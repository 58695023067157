
import { defineComponent } from "vue";
import BaseHeader2 from "@/components/BaseHeader2.vue";
import MenuEntry from "@/components/member/MenuEntry.vue";
import BaseTextarea from "@/components/BaseTextarea.vue";
import DefaultButton from "@/components/common/DefaultButton.vue";
import CheckCircleIcon from "icons/CheckCircle.vue";
import CheckboxBlankCircleOutlineIcon from "icons/CheckboxBlankCircleOutline.vue";

export default defineComponent({
  name: "Report",
  components: {
    BaseHeader2,
    MenuEntry,
    BaseTextarea,
    DefaultButton,
    CheckCircleIcon,
    CheckboxBlankCircleOutlineIcon,
  },
  data() {
    return {
      options: "",
      detail: null as null | string,
      contentId: null as null | string,
      contentType: null as null | string,
    };
  },
  computed: {
    isFormValid(): null | string {
      let ret: string = this.options;
      let detail: null | string = this.detail;

      if (this.options == "기타") {
        return ret && detail;
      }
      return ret;
    },
  },
  methods: {
    submit() {
      let content = this.options;
      if (this.options == "기타") {
        content = this.detail as string;
      }
      this.$axios
        .post("/urgent/mix/report", {
          model_name: this.contentType,
          model_pk: this.contentId,
          content,
        })
        .then((result) => {
          this.$flutter.callHandler("showToast", {
            message: "접수되었습니다.",
            color: "success.",
          });
          this.$router.go(-1);
        });
    },
  },
  mounted() {
    const query =
      (this.$route.query as {
        contentId: string;
        contentType: string;
      } | null) || null;
    if (query) {
      this.contentId = query.contentId;
      this.contentType = query.contentType;
    }
  },
});
